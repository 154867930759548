import { Badge, BlockStack, Button, ButtonGroup, Card, DescriptionList, FooterHelp, InlineGrid, Link, List, Page, Text } from '@shopify/polaris';
import React, { useCallback, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { PlusIcon } from '@shopify/polaris-icons';
import __ from 'languages/index';
import WalletTypeModal from './wallet_modal';
import { useDeleteWallet, useGetWallets } from 'queries/wallet.query';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
import { useSettings } from 'context/SettingsContext';
export default function WalletList() {
  const {
    refetch: getEntities,
    data: entities,
    isFetched,
    isSuccess
  } = useGetWallets({
    limit: 30
  });
  const {
    settings: settings,
    loading: loadingSetting
  } = useSettings();
  const {
    mutate: deleteEntity
  } = useDeleteWallet();
  const currentInteractID = useRef(null);
  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  const handleDelete = useCallback(async wallet_id => {
    const confirmed = await showDeleteConfirm('Xoá một ví dẫn tới xóa toàn bộ tài khoản liên quan', 'Toàn bộ các tài khoản ví của người dùng sẽ bị xóa bỏ. Toàn bộ các record liên quan đều bị xóa cùng ví. Bạn thực sự muốn xóa?');
    if (confirmed) {
      // Xử lý hành động xóa
      deleteEntity(wallet_id);
    }
  }, []);
  const [showNewModal, setShowNewModal] = useState(false);
  const onCloseWalletTypeModal = useCallback(async () => {
    currentInteractID.current = null;
    setShowNewModal(false);
  }, []);
  const setEditThisRecord = useCallback((thisID: any) => {
    currentInteractID.current = thisID;
    setShowNewModal(true);
  }, [currentInteractID]);

  /**
   * ENd save quick note
   */

  return <>
      <WalletTypeModal show={showNewModal} onClose={onCloseWalletTypeModal} wallet_type_id={currentInteractID.current} />

      <Helmet>
        <title>Cài đặt ví</title>
      </Helmet>
      <Page title="Cài đặt ví và hệ thống tài chính" subtitle="Ví và các loại ví" primaryAction={{
      content: 'Thêm ví',
      icon: PlusIcon,
      onAction: () => setShowNewModal(true)
    }}>
        <DescriptionList items={[{
        term: __('wallet_setting_section_title'),
        description: <>
                  {isSuccess && entities.body.length < 1 && <>
                      <Text as="p">Chưa có gì ở đây cả!</Text>
                    </>}
                  {isSuccess && entities.body.map((walletElement, index) => {
            return <div key={'wallet_id_' + index} className="bankCardWithBackground">
                          <Card roundedAbove="sm">
                            <BlockStack gap="200">
                              <InlineGrid columns="1fr auto">
                                <Text as="h2" variant="headingSm">
                                  <Link removeUnderline url={`/wallet/${walletElement.id}`}>
                                    {walletElement.wallet_name} {walletElement.is_default === 1 && <Badge tone="attention">Mặc định</Badge>}
                                  </Link>
                                </Text>
                                <ButtonGroup>
                                  <Button variant="plain" onClick={() => setEditThisRecord(walletElement.id)}>
                                    Edit
                                  </Button>
                                  {walletElement.is_default === 0 && <Button variant="plain" onClick={() => handleDelete(walletElement.id)}>
                                      Delete
                                    </Button>}
                                </ButtonGroup>
                              </InlineGrid>
                              <BlockStack gap="400">
                                <Text as="p" variant="bodyMd">
                                  {walletElement.wallet_description}
                                </Text>
                                <Text as="h3" variant="headingSm" fontWeight="medium">
                                  Đơn vị tính: {__(walletElement.wallet_unit)} - Ký hiệu {walletElement.wallet_sign}{' '}
                                  {walletElement.rank_allowed === 1 && ' - Cho phép xếp hạng'}
                                </Text>
                              </BlockStack>
                            </BlockStack>
                          </Card>
                          <br />
                        </div>;
          })}
                </>
      }]} />

        <br />
        <br />
      </Page>
    </>;
}