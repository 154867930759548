import { compiledBlock as _compiledBlock } from "million/react";
const DateTimeInput_1 = _compiledBlock(_props => <div className="date-input-container">
      <label>{_props.v0}</label>
      <div className={_props.v1}>
        {_props.v2}
        <span>/</span>
        {_props.v3}
        <span>/</span>
        {_props.v4}
      </div>
      {_props.v5}
    </div>, {
  name: "DateTimeInput_1",
  portals: ["v0", "v2", "v3", "v4", "v5"]
});
const DateTimeInput = function DateTimeInput({
  onDateChange,
  label,
  error,
  initialDate
}: DateInputProps) {
  const initialDateObject = useRef<Date | null>(null);
  let isValidDate = helpers.isDate(initialDate);
  if (initialDate && isValidDate && !initialDateObject.current) {
    initialDateObject.current = new Date(initialDate);
  }
  const initialYear = initialDateObject.current ? initialDateObject.current.getFullYear().toString() : '';
  const initialMonth = initialDateObject.current ? (initialDateObject.current.getMonth() + 1).toString().padStart(2, '0') : '';
  const initialDay = initialDateObject.current ? initialDateObject.current.getDate().toString().padStart(2, '0') : '';
  const [day, setDay] = useState<string>(initialDay);
  const [month, setMonth] = useState<string>(initialMonth);
  const [year, setYear] = useState<string>(initialYear);
  const dayRef = useRef<HTMLInputElement>(null);
  const monthRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);
  const startCallback = useRef(false);
  // click vào thì select toàn bộ
  const handleFocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
    startCallback.current = true;
  }, []);
  const onChangeCallback = useMemo(() => helpers.debounce(_value => onDateChange(_value), 500), []);
  useEffect(() => {
    // Kiểm tra nếu tất cả các giá trị ngày, tháng và năm đã được nhập, và không có trường nào được focus, thì gọi callback
    if (day && month && year && startCallback.current) {
      onChangeCallback({
        day,
        month,
        year
      });
    }
  }, [day, month, year, startCallback]);
  const [dayError, setDayError] = useState(false);
  const handleDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '' || helpers.isNumeric(value)) setDay(value);
    if (value) {
      if (value.length === 2 && Number(value) > 0 && Number(value) <= 31) {
        setDayError(false);
        monthRef.current?.focus();
      } else {
        setDayError(true);
      }
    }
  };
  const [monthError, setMonthError] = useState(false);
  const handleMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '' || helpers.isNumeric(value)) setMonth(value);
    if (value) {
      if (value.length === 2 && Number(value) > 0 && Number(value) <= 12) {
        setMonthError(false);
        yearRef.current?.focus();
      } else {
        setMonthError(true);
      }
    }
  };
  const [yearError, setYearError] = useState(false);
  const handleYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value || ``;
    if (value === '' || helpers.isNumeric(value)) setYear(value);
    if (value) {
      if (isNaN(Number(value)) || Number(value) < 1200 || Number(value) > 2099) {
        setYearError(true);
      } else {
        setYearError(false);
      }
    }
  };
  return /*@million jsx-skip*/<DateTimeInput_1 v0={label} v1={`date-input ${error && 'has_error'}`} v2={<input ref={dayRef} className={dayError && 'input_has_error'} type="text" value={day} onChange={handleDayChange} onFocus={handleFocus} placeholder="DD" maxLength={2} />} v3={<input ref={monthRef} className={monthError && 'input_has_error'} readOnly={!!dayError} type="text" value={month} onChange={handleMonthChange} onFocus={handleFocus} placeholder="MM" maxLength={2} />} v4={<input ref={yearRef} className={yearError && 'input_has_error'} readOnly={!!monthError} type="text" value={year} onChange={handleYearChange} onFocus={handleFocus} placeholder="YYYY" maxLength={4} />} v5={error && <p style={{
    color: 'red'
  }}>* {error}</p>} />;
};
import { block as _block } from "million/react";
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import 'media/css/dateTimeInput.scss'; // Đừng quên import file CSS để styling
import helpers from 'helpers/index';
import dateandtime from 'date-and-time';
interface DateInputProps {
  onDateChange: (date: {
    day: string;
    month: string;
    year: string;
  }) => void;
  label: string;
  /** Date, YYYY-MM-DD */
  initialDate?: string;
  error?: string;
}
export default DateTimeInput;