import { BlockStack, Button, Card, ContextualSaveBar, Divider, FormLayout, Page, Select, TextField } from '@shopify/polaris';
import { useField, useForm } from '@shopify/react-form';
import SkeletonLoading from 'components/skeletonPageLoading';
import { useNotification } from 'context/NotificationContext';
import { useSettings } from 'context/SettingsContext';
import __ from 'languages/index';
import { useUpdateSetting } from 'queries/setting.query';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { PlusIcon, SaveIcon, DeleteIcon } from '@shopify/polaris-icons';
export default function ProductSettings() {
  const {
    addNotification
  } = useNotification();
  const {
    settings: entities
  } = useSettings();
  const {
    mutateAsync: updateEntity,
    error,
    isSuccess: updateSuccess,
    isPending: updating
  } = useUpdateSetting();

  /** Hết code lấy user role ra ... */
  const useFields = {
    _product_super_meta: useField<{
      label: string;
      description: string;
      key: string;
      type: string;
    }[]>({
      value: entities?._product_super_meta || [],
      validates: []
    })
  };
  const {
    fields,
    submit,
    dirty,
    reset: resetForm
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        await updateEntity({
          /** extra */
          _product_super_meta: JSON.stringify(values._product_super_meta)
        });
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });

  /**
   * Notification
   */

  useEffect(() => {
    if (error) {
      addNotification('error', error.message);
    }
  }, [error]);
  useEffect(() => {
    if (updateSuccess) addNotification('info', __('updated_successfully'));
  }, [updateSuccess]);
  return <>
      <Helmet>
        <title>Cài đặt sản phẩm</title>
      </Helmet>

      <Page title="Cài đặt sản phẩm" narrowWidth primaryAction={{
      icon: SaveIcon,
      content: 'Lưu',
      onAction: submit,
      loading: updating
    }}>
        <BlockStack gap="400">
          {fields._product_super_meta.value.map((attribute: {
          label: string;
          description: string;
          key: string;
          type: 'string' | 'number' | 'boolean';
        }, index) => <Card>
                <BlockStack gap="400">
                  <TextField label={`Thuộc tính: ${attribute.label}`} autoComplete="off" requiredIndicator value={attribute.label} onChange={value => {
              const updatedAttributes = [...fields._product_super_meta.value];
              updatedAttributes[index].label = value;
              fields._product_super_meta.onChange(updatedAttributes);
            }} labelAction={{
              content: 'Xóa',
              // icon: DeleteIcon,
              onAction: () => {
                const updatedAttributes = [...fields._product_super_meta.value];
                updatedAttributes.splice(index, 1);
                fields._product_super_meta.onChange(updatedAttributes);
              }
            }} />

                  <TextField label="Mô tả ngắn" autoComplete="off" value={attribute.description} multiline={3} onChange={value => {
              const updatedAttributes = [...fields._product_super_meta.value];
              updatedAttributes[index].description = value;
              fields._product_super_meta.onChange(updatedAttributes);
            }} />

                  <FormLayout.Group>
                    <TextField label="Tên trường dữ liệu" autoComplete="off" value={attribute.key} onChange={value => {
                const updatedAttributes = [...fields._product_super_meta.value];
                updatedAttributes[index].key = value;
                fields._product_super_meta.onChange(updatedAttributes);
              }} />
                    <Select label="Loại dữ liệu" options={[{
                label: 'Chữ',
                value: 'string'
              }, {
                label: 'Số',
                value: 'number'
              }, {
                label: 'Có/Không',
                value: 'boolean'
              }]} value={attribute.type} onChange={value => {
                const updatedAttributes = [...fields._product_super_meta.value];
                updatedAttributes[index].type = value;
                fields._product_super_meta.onChange(updatedAttributes);
              }} />
                  </FormLayout.Group>
                </BlockStack>
              </Card>)}
        </BlockStack>

        <br />
        <Divider />
        <br />

        <Button onClick={() => {
        const updatedAttributes = [...fields._product_super_meta.value, {
          key: '',
          description: '',
          label: '',
          type: 'string'
        }];
        fields._product_super_meta.onChange(updatedAttributes);
      }} icon={PlusIcon} fullWidth variant="primary">
          Thêm thuộc tính mới
        </Button>

        {dirty && <ContextualSaveBar message="Thay đổi chưa được lưu" saveAction={{
        onAction: () => submit(),
        loading: updating
      }} discardAction={{
        onAction: () => resetForm()
      }} />}
      </Page>
      <br />
      <br />
    </>;
}