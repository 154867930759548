import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import queryClient from 'queries';

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/

type MediaThumbnail = {
    "128x128"?: string;
    "256x169"?: string;
    "300x200"?: string;
    "512x512"?: string;
    "scale-128"?: string;
    "scale-256"?: string;
    "scale-512"?: string;
    "scale-1024"?: string;
};

type Media = {
    media_id: string;
    media_url: string;
    media_thumbnail?: MediaThumbnail | null;
};

type Product = {
    product_name: string;
    product_id: string;
    product_thumbnail_to_media: Media;
};

type ProductVariant = {
    variant_id: string;
    variant_name: string;
    variant_thumbnail_to_media: Media;
};

type Customer = {
    user_id: string;
    display_name: string;
    user_email: string;
    user_phonenumber: string;
};

export type GroupIncomeOrder = {
    group_income_order_id?: string;
    order_id?: string;
    product_id?: string;
    variant_id?: string | null;
    quantity?: number;
    group_income_value?: number;
    total_group_income_value?: number;
    customer_id?: string;
    createdAt?: string;
    product?: Product;
    product_variant?: ProductVariant | null;
    customer?: Customer;
};


type IQuery = GroupIncomeOrder & IQueryParams;


/*
* List of all group_income
*/

export function useGetGroupIncome() {
    return useMutation({
        mutationKey: ["group_income_order/fetch_entity_list"],
        mutationFn: (object: IQuery) => axios.get<GroupIncomeOrder[]>(`/group_income_order${helpers.buildEndUrl(object)}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
    });
}


type TypedSumarize = {
    "_sum": {
        "total_group_income_value": number | null,
        "quantity": number | null
    },
    "_count": {
        "order_id": number
    }
}

export function useGetGroupIncomeSumarize() {
    return useMutation({
        mutationKey: ['group_income_order/sumarize'],
        mutationFn: (object: IQuery) => axios.get<TypedSumarize>(`/group_income_order/sumarize/${helpers.buildEndUrl(object)}`).then(response => response.data),
        retry: 1,
    });
}

