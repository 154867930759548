import { BlockStack, Card, Page, TextField, Text, Checkbox, ContextualSaveBar, Select, Grid, Divider, ChoiceList, InlineGrid, Box } from '@shopify/polaris';
import { lengthLessThan, notEmpty, useField, useForm } from '@shopify/react-form';
import SkeletonPageLoading2 from 'components/skeletonPageLoading2';
import __ from 'languages/index';
import { useUpdateSetting } from 'queries/setting.query';
import { Helmet } from 'react-helmet-async';
import QuickSearchWallet from 'components/quickSearchWallet';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useCallback, useEffect } from 'react';
import __helpers from 'helpers/index';
import { useUserRoleContext } from 'context/UserRoleContext';
import { useSettings } from 'context/SettingsContext';
import { useNotification } from 'context/NotificationContext';
const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,
  // change background colour if dragging
  background: isDragging ? 'lightgreen' : '#FFF',
  // styles we need to apply on draggables
  ...draggableStyle
});
const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : '#111',
  display: 'flex',
  padding: grid,
  overflow: 'auto'
});
export default function AffiliateSetup() {
  const {
    settings
  } = useSettings();

  /** Lấy user role ra ... */
  const {
    allRoleSelection: allRoles
  } = useUserRoleContext();
  /** Hết code lấy user role ra ... */

  const {
    mutateAsync: updateEntity,
    error,
    isSuccess: updateSuccess,
    isPending: updating
  } = useUpdateSetting();
  const {
    addNotification
  } = useNotification();
  useEffect(() => {
    if (updateSuccess) {
      addNotification('info', 'Cập nhật cài đặt Affiliate thành công.');
    }
  }, [updateSuccess]);
  const useFields = {
    must_add_referrer: useField<number>({
      value: Number(settings?.must_add_referrer ?? 0),
      validates: []
    }),
    referrer_general_setting_user_condition: useField<string>({
      value: settings?.referrer_general_setting_user_condition || 'after_active_account',
      validates: []
    }),
    referrer_general_setting_direct_user_receive_value: useField<string>({
      value: settings?.referrer_general_setting_direct_user_receive_value,
      validates: []
    }),
    referrer_level_deep: useField<string>({
      value: settings?.referrer_level_deep ?? 1,
      validates: [notEmpty('Không được để trống.'), inputVal => {
        if (!__helpers.isNumeric(inputVal)) return 'Yêu cầu là một số hợp lệ.';
        if (Number(inputVal) > 9) return 'Luôn luôn là dưới 10 tầng.';
        if (Number(inputVal) < 0) return 'Không hợp lệ, độ sâu luôn là một số dương.';
      }]
    }),
    referrer_general_setting_direct_user_receive_wallet: useField<string>({
      value: settings?.referrer_general_setting_direct_user_receive_wallet,
      validates: []
    }),
    commission_user_receive_wallet: useField<string>({
      value: settings?.commission_user_receive_wallet,
      validates: []
    }),
    rebate_user_receive_wallet: useField<string>({
      value: settings?.rebate_user_receive_wallet,
      validates: []
    }),
    tiered_rebate_user_receive_wallet: useField<string>({
      value: settings?.tiered_rebate_user_receive_wallet,
      validates: []
    }),
    affiliate_ignore_user_role_list: useField<string[]>({
      value: String(settings?.affiliate_ignore_user_role_list || '').split(','),
      validates: []
    }),
    affiliate_ignore_any_rule_user_role_list: useField<string[]>({
      value: String(settings?.affiliate_ignore_any_rule_user_role_list || '').split(','),
      validates: []
    }),
    rebate_ignore_user_role_list: useField<string[]>({
      value: String(settings?.rebate_ignore_user_role_list || '').split(','),
      validates: []
    }),
    tiered_rebate_ignore_user_role_list: useField<string[]>({
      value: String(settings?.tiered_rebate_ignore_user_role_list || '').split(','),
      validates: []
    }),
    affiliate_allow_service: useField<string[]>({
      value: String(settings?.affiliate_allow_service || '').split(','),
      validates: []
    }),
    affiliate_priority: useField<string[]>({
      value: String(settings?.affiliate_priority || 'commission,rebate,tiered_rebate').split(','),
      validates: []
    }),
    affiliate_policy: useField<string>({
      value: String(settings?.affiliate_policy || 'all'),
      validates: []
    }),
    tiered_rebate_policy: useField<string>({
      value: String(settings?.tiered_rebate_policy || 'from_buyer'),
      validates: []
    }),
    tiered_rebate_added_up: useField<string>({
      value: String(settings?.tiered_rebate_added_up || 'added_up'),
      validates: []
    }),
    group_income_calculator_turnon: useField<string>({
      value: String(settings?.group_income_calculator_turnon || '0'),
      validates: []
    }),
    group_income_calculator_wallet_id: useField<string>({
      value: settings?.group_income_calculator_wallet_id,
      validates: []
    }),
    withdraw_transfer_content: useField<string>({
      value: settings?.withdraw_transfer_content ?? '',
      validates: [lengthLessThan(51, 'Nội dung chuyển khoản bao gồm tối đa 50 chữ cái không dấu, không bao gồm các kí tự đặc biệt.'), inputVal => {
        if (inputVal && __helpers.isUTF8(inputVal)) return 'Không được sử dụng các ký tự đặc biệt, mã UTF8 ở trường này.';
      }]
    })
  };
  const {
    fields,
    submit,
    dirty,
    reset: resetForm
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        await updateEntity({
          must_add_referrer: values.must_add_referrer,
          referrer_general_setting_user_condition: values.referrer_general_setting_user_condition,
          referrer_general_setting_direct_user_receive_value: values.referrer_general_setting_direct_user_receive_value,
          referrer_level_deep: values.referrer_level_deep,
          referrer_general_setting_direct_user_receive_wallet: values.referrer_general_setting_direct_user_receive_wallet,
          commission_user_receive_wallet: values.commission_user_receive_wallet,
          rebate_user_receive_wallet: values.rebate_user_receive_wallet,
          tiered_rebate_user_receive_wallet: values.tiered_rebate_user_receive_wallet,
          tiered_rebate_policy: values.tiered_rebate_policy || 'from_buyer',
          tiered_rebate_added_up: values.tiered_rebate_added_up || 'added_up',
          affiliate_ignore_user_role_list: values.affiliate_ignore_user_role_list,
          affiliate_ignore_any_rule_user_role_list: values.affiliate_ignore_any_rule_user_role_list,
          rebate_ignore_user_role_list: values.rebate_ignore_user_role_list,
          tiered_rebate_ignore_user_role_list: values.tiered_rebate_ignore_user_role_list,
          affiliate_allow_service: values.affiliate_allow_service,
          affiliate_priority: values.affiliate_priority,
          affiliate_policy: values.affiliate_policy,
          /** bật group_income_calculator_turnon */
          group_income_calculator_turnon: values.group_income_calculator_turnon,
          group_income_calculator_wallet_id: values.group_income_calculator_wallet_id,
          withdraw_transfer_content: values.withdraw_transfer_content
        });
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex): string[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result as string[];
  };
  const onDragEnd = useCallback(result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(useFields.affiliate_priority.value, result.source.index, result.destination.index);
    useFields.affiliate_priority.onChange(items);
  }, [useFields.affiliate_priority.value]);
  return <>
      <Helmet>
        <title>Cài đặt quy tắc Affiliate</title>
      </Helmet>

      <Page title="Cài đặt Affiliate">
        <BlockStack gap="400">
          <InlineGrid columns={{
          xs: '1fr',
          md: '2fr 5fr'
        }} gap="400">
            <Box as="section" paddingInlineStart={{
            xs: '400',
            sm: '0'
          }} paddingInlineEnd={{
            xs: '400',
            sm: '0'
          }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Cài đặt chung
                </Text>
                <Text as="p" variant="bodyMd">
                  Đây là các quy tắc chung, được áp dụng toàn bộ hệ thống
                </Text>
              </BlockStack>
            </Box>
            <Box>
              <Card>
                <BlockStack gap="400">
                  <Text as="h2" variant="headingLg">
                    Thưởng giới thiệu thành viên
                  </Text>
                  <Checkbox label="Bắt buộc thêm mã giới thiệu khi đăng ký" helpText="Khi đăng ký mới tài khoản bằng hình thức nào, đều sẽ yêu cầu thêm mã giới thiệu." checked={fields.must_add_referrer.value === 1} onChange={e => fields.must_add_referrer.onChange(Number(e))} />

                  <TextField label="Độ sâu của tính toán và thống kê doanh thu?" autoComplete="off" helpText={`Hệ thống theo dõi vô hạn cấp, nhưng để phục vụ cho thống kê, thông thường sẽ chỉ giới hạn một mức dưới 10.`} type="number" {...fields.referrer_level_deep} />

                  <Text as="h3" variant="headingMd">
                    Thưởng cho người giới thiệu trực tiếp
                  </Text>
                  <Select label="Điều kiện người đăng ký mới" options={[{
                  label: 'Sau khi thêm người giới thiệu',
                  value: 'after_active_account'
                }, {
                  label: 'Sau khi xác minh profile',
                  value: 'after_profile_verified'
                }]} helpText="Khi người đăng ký mới thêm mã giới thiệu, hoặc admin thêm mã giới thiệu cho khách hàng, người giới thiệu trực tiếp sẽ nhận được phần thưởng." onChange={v => fields.referrer_general_setting_user_condition.onChange(v)} value={String(fields.referrer_general_setting_user_condition.value)} />

                  <Grid>
                    <Grid.Cell columnSpan={{
                    xs: 6,
                    sm: 3,
                    md: 3,
                    lg: 6,
                    xl: 6
                  }}>
                      <TextField label="Họ sẽ được?" autoComplete="off" {...fields.referrer_general_setting_direct_user_receive_value} />
                    </Grid.Cell>

                    <Grid.Cell columnSpan={{
                    xs: 6,
                    sm: 3,
                    md: 3,
                    lg: 6,
                    xl: 6
                  }}>
                      <QuickSearchWallet title="Ví nhận" current_wallet_id={settings?.referrer_general_setting_direct_user_receive_wallet} onClose={wallet_id => useFields.referrer_general_setting_direct_user_receive_wallet.onChange(wallet_id)} />
                    </Grid.Cell>
                  </Grid>

                  <Divider />
                  <Text as="h2" variant="headingLg">
                    Ví nhận Affiliate bán hàng
                  </Text>

                  <QuickSearchWallet title="Khi một đơn hàng được hoàn thành, tiền affiliate sẽ vào ví" current_wallet_id={settings?.commission_user_receive_wallet} onClose={wallet_id => useFields.commission_user_receive_wallet.onChange(wallet_id)} />

                  <Divider />
                  <Text as="h2" variant="headingLg">
                    Ví nhận chiết khấu trực tiếp (rebate)
                  </Text>
                  <QuickSearchWallet title="Ví nhận tiền chiết khấu" current_wallet_id={settings?.rebate_user_receive_wallet} onClose={wallet_id => useFields.rebate_user_receive_wallet.onChange(wallet_id)} />

                  <Divider />

                  <Text as="h2" variant="headingLg">
                    Ví nhận chiết khấu phân tầng (tired-rebate)
                  </Text>
                  <QuickSearchWallet title="Ví nhận tiền chiết khấu phân tầng" current_wallet_id={settings?.tiered_rebate_user_receive_wallet} onClose={wallet_id => useFields.tiered_rebate_user_receive_wallet.onChange(wallet_id)} />
                </BlockStack>
              </Card>
            </Box>
          </InlineGrid>

          <InlineGrid columns={{
          xs: '1fr',
          md: '2fr 5fr'
        }} gap="400">
            <Box as="section" paddingInlineStart={{
            xs: '400',
            sm: '0'
          }} paddingInlineEnd={{
            xs: '400',
            sm: '0'
          }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Cài đặt nâng cao
                </Text>
                <Text as="p" variant="bodyMd">
                  Đây là các quy tắc chung, được áp dụng toàn bộ hệ thống
                </Text>
              </BlockStack>
            </Box>
            <Box>
              <Card>
                <BlockStack gap="400">
                  <Text as="h3" variant="headingMd">
                    Hoa hồng (Commission)
                  </Text>

                  <ChoiceList allowMultiple title="Danh sách vai trò không được NHẬN HOA HỒNG." choices={allRoles} selected={useFields.affiliate_ignore_user_role_list.value || []} onChange={val => useFields.affiliate_ignore_user_role_list.onChange(val)} />
                  <br />

                  <ChoiceList allowMultiple title="Các vai trò sau đây KHI MUA HÀNG sẽ không kích hoạt phân chia hoa hồng." choices={allRoles} selected={useFields.affiliate_ignore_any_rule_user_role_list.value || []} onChange={val => useFields.affiliate_ignore_any_rule_user_role_list.onChange(val)} />
                </BlockStack>

                <br />
                <Divider />
                <br />

                <BlockStack gap="400">
                  <Text as="h3" variant="headingMd">
                    Chiết khấu / hoàn tiền trực tiếp (rebate)
                  </Text>

                  <ChoiceList allowMultiple title="Danh sách vai trò không được tính chiết khấu KHI MUA HÀNG" choices={allRoles} selected={useFields.rebate_ignore_user_role_list.value || []} onChange={val => useFields.rebate_ignore_user_role_list.onChange(val)} />
                </BlockStack>

                <br />
                <Divider />
                <br />
                <BlockStack gap="400">
                  <Text as="h3" variant="headingMd">
                    Chiết khấu phân tầng (tiered_rebate)
                  </Text>

                  <ChoiceList allowMultiple title="Danh sách vai trò KHÔNG kích hoạt chiết khấu phân tầng KHI MUA HÀNG." choices={allRoles} selected={useFields.tiered_rebate_ignore_user_role_list.value || []} onChange={val => useFields.tiered_rebate_ignore_user_role_list.onChange(val)} />

                  <ChoiceList title="Quy tắc phân chia chiết khấu dựa theo tiêu chí nào?" choices={[{
                  label: 'Tính từ người mua',
                  value: 'from_buyer',
                  helpText: `Tính từ người mua, nếu người mua thỏa mãn quy tắc, đều được tính.`
                }, {
                  label: 'Tính từ người giới thiệu',
                  value: 'from_referrer',
                  helpText: `Tính từ người giới thiệu, mọi người giới thiệu thỏa mãn điều kiện đều được tính.`
                }]} selected={[useFields.tiered_rebate_policy.value]} onChange={v => useFields.tiered_rebate_policy.onChange(v[0])} />

                  <ChoiceList title="Cộng dồn lên tầng trên khi khuyết thiếu tầng ở giữa?" choices={[{
                  label: 'Có cộng dồn',
                  value: 'added_up',
                  helpText: `Khi tầng nào đó bị khuyết thiếu, chiết khấu được cộng dồn lên tầng đại lý.`
                }, {
                  label: 'Không cộng dồn',
                  value: 'not_added_up',
                  helpText: `Tầng nào thỏa mãn giá trị đều chỉ được nhận bằng đó, cho dù có khuyết thiếu.`
                }]} selected={[useFields.tiered_rebate_added_up.value]} onChange={v => useFields.tiered_rebate_added_up.onChange(v[0])} />
                </BlockStack>
              </Card>
            </Box>
          </InlineGrid>

          <InlineGrid columns={{
          xs: '1fr',
          md: '2fr 5fr'
        }} gap="400">
            <Box as="section" paddingInlineStart={{
            xs: '400',
            sm: '0'
          }} paddingInlineEnd={{
            xs: '400',
            sm: '0'
          }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Quy tắc chung
                </Text>
                <Text as="p" variant="bodyMd">
                  Cài đặt quy tắc cho việc tính hoa hồng, chiết khấu
                </Text>
              </BlockStack>
            </Box>
            <Box>
              <Card>
                <BlockStack gap="400">
                  <Text as="p" variant="bodySm" tone="subdued">
                    * Quy tắc này áp dụng trên toàn hệ thống, ghi đè các cài đặt khác nếu có. Khi một đơn hàng được tạo, nó sẽ được ghi nhận chính
                    sách ngay tại thời điểm tạo và không phụ thuộc vào việc chỉnh sửa quy tắc.
                  </Text>

                  <ChoiceList title="Quy tắc nào được bật?" allowMultiple choices={[{
                  label: 'Hoa hồng (commission)',
                  value: 'commission',
                  helpText: 'Hoa hồng giới thiệu được bật trên toàn hệ thống, sản phẩm nào phù hợp quy tắc sẽ được tính cho khách hàng.'
                }, {
                  label: 'Chiết khấu (rebate)',
                  value: 'rebate',
                  helpText: 'Khi khách hàng mua sản phẩm, họ sẽ được trả lại một số tiền nhất định tùy theo sản phẩm và tùy theo vai trò của họ trên hệ thống.'
                }, {
                  label: 'Chiết khấu phân tầng',
                  value: 'tiered_rebate',
                  helpText: 'Khi khách hàng mua sản phẩm, các tầng liên quan tới người mua sẽ nhận được giá trị tương ứng set trong từng sản phẩm.'
                }]} selected={useFields.affiliate_allow_service.value} onChange={v => useFields.affiliate_allow_service.onChange(v)} />

                  <Text as="h3" variant="headingMd">
                    Quy tắc ưu tiên (ưu tiên giảm dần từ trái qua phải)
                  </Text>

                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                      {(provided, snapshot) => <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                          {useFields.affiliate_priority.value.map((item, index) => <Draggable key={item} draggableId={item} index={index}>
                              {(provided, snapshot) => <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                  {item}
                                </div>}
                            </Draggable>)}
                          {provided.placeholder}
                        </div>}
                    </Droppable>
                  </DragDropContext>

                  <Text as="h3" variant="headingMd">
                    Quy tắc tính hoa hồng và chiết khấu
                  </Text>

                  <ChoiceList title="" choices={[{
                  label: 'Mọi quy tắc phù hợp',
                  value: 'all',
                  helpText: 'Cứ khớp các điều kiện là sẽ được tính.'
                }, {
                  label: 'Khi khớp điều kiện đầu tiên thì dừng lại',
                  value: 'first_match_then_stop',
                  helpText: 'Nếu có nhiều điều kiện phù hợp, điều kiện đầu tiên trong danh sách ưu tiên sẽ được tính.'
                }]} selected={[useFields.affiliate_policy.value]} onChange={v => useFields.affiliate_policy.onChange(v[0])} />
                </BlockStack>
              </Card>
            </Box>
          </InlineGrid>

          <InlineGrid columns={{
          xs: '1fr',
          md: '2fr 5fr'
        }} gap="400">
            <Box as="section" paddingInlineStart={{
            xs: '400',
            sm: '0'
          }} paddingInlineEnd={{
            xs: '400',
            sm: '0'
          }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Tính doanh thu đội nhóm
                </Text>
                <Text as="p" variant="bodyMd">
                  Cài đặt quy tắc và giá trị cho doanh thu đội nhóm
                </Text>
              </BlockStack>
            </Box>
            <Box>
              <Card>
                <BlockStack gap="400">
                  <Checkbox label="Bật tính năng doanh thu đội nhóm" checked={useFields.group_income_calculator_turnon.value === '1'} onChange={v => useFields.group_income_calculator_turnon.onChange(v ? '1' : '0')} />

                  <Text as="p" variant="bodySm" tone="subdued">
                    * Khi bật doanh thu đội nhóm, trong các sản phẩm sẽ có trường để điều chỉnh giá trị khi một ai đó mua sản phẩm. Với mỗi đơn hàng
                    có sản phẩm đó, doanh thu sẽ được tính và ghi nhận vào doanh thu đội nhóm.
                  </Text>

                  {useFields.group_income_calculator_turnon.value === '1' && <>
                      <QuickSearchWallet title="Ví nhận/ đơn vị tính" current_wallet_id={settings?.group_income_calculator_wallet_id} onClose={wallet_id => useFields.group_income_calculator_wallet_id.onChange(wallet_id)} />
                      <Text as="p" variant="bodySm" tone="subdued">
                        * Giá trị của doanh thu đội nhóm được tính khi trong sản phẩm có cài giá trị lớn hơn 0.
                      </Text>
                    </>}
                </BlockStack>
              </Card>
            </Box>
          </InlineGrid>

          <InlineGrid columns={{
          xs: '1fr',
          md: '2fr 5fr'
        }} gap="400">
            <Box as="section" paddingInlineStart={{
            xs: '400',
            sm: '0'
          }} paddingInlineEnd={{
            xs: '400',
            sm: '0'
          }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Rút tiền
                </Text>
                <Text as="p" variant="bodyMd">
                  Cài đặt quy tắc chung cho việc rút tiền
                </Text>
              </BlockStack>
            </Box>
            <Box>
              <Card>
                <BlockStack gap="400">
                  <TextField label="Nội dung chuyển khoản mặc định cho QR thanh toán khi CTV, đối tác rút tiền" autoComplete="off" maxLength={50} {...fields.withdraw_transfer_content} />
                </BlockStack>
              </Card>
            </Box>
          </InlineGrid>
        </BlockStack>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Page>
      {dirty && <ContextualSaveBar message="Thay đổi chưa được lưu" saveAction={{
      onAction: () => submit(),
      loading: updating,
      disabled: updating
    }} discardAction={{
      onAction: () => resetForm()
    }} />}
    </>;
}