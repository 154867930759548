import __helpers from "helpers/index";
import { useRefreshMyToken } from "queries/session.query";
import { useCallback, useEffect } from "react";

/** Khi mới truy cập, refresh luôn token cho an toàn */
export default function useRefreshTokenHelper() {
    const { mutateAsync: RefreshMyToken } = useRefreshMyToken();

    const loadRefreshToken = useCallback(async () => {
        let RT = __helpers.cookie_get("RT");
        let EA = __helpers.cookie_get("EA");
        if (!EA || !RT || !__helpers.isNumeric(EA)) {
            throw new Error('refresh_token_missing');
        }

        let hours_passed = Math.floor((Number(EA) - new Date().getTime()) / (1000 * 60 * 60));
        if (hours_passed > 4) {
            console.log('DO NOT NEED TO REFRESH TOKEN...');
            return;
        }

        try {
            let { access_token, refresh_token, expires_at } = await RefreshMyToken(RT);
            __helpers.cookie_set("AT", access_token, 30);
            __helpers.cookie_set("RT", refresh_token, 30);
            __helpers.cookie_set("EA", expires_at, 30);
        } catch (e) {
            console.error(e, "refresh token error.");
            throw e; // Re-throw lỗi để có thể bắt khi gọi hàm
        }
    }, [RefreshMyToken]);

    const initLoadingUserData = useCallback(async () => {
        try {
            await loadRefreshToken();
        } catch (e) {
            console.error(e, "REFRESH TOKEN ERROR");
        }

        // Đặt interval để làm mới token
        setInterval(() => {
            loadRefreshToken();
        }, 120000); // 2 phút
    }, [loadRefreshToken]);

    useEffect(() => {
        initLoadingUserData();
    }, [initLoadingUserData]);

    // Trả về hàm loadRefreshToken để gọi bên ngoài
    // return loadRefreshToken;
}
