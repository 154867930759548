import { QueryCache, QueryClient } from "@tanstack/react-query";

// const queryClient = new QueryClient();
const queryClient = new QueryClient({
    queryCache: new QueryCache({
        // onError: (error) => { console.error(error, 'reactQuery tell me this is error') }
    }),
});
export default queryClient;



/**
 * Model for general query in Restful API
 */

export type IQueryParams = {
    query?: string;
    page?: number;
    limit?: number;
    sort?: string;
    filter?: string;
    [propName: string]: any;
};
