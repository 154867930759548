import { Button } from '@shopify/polaris';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PlayCircleIcon, PauseCircleIcon } from '@shopify/polaris-icons';
function AudioPlayer({
  audioUrl = '',
  id,
  idPlaying,
  currentlyPlayingRef,
  setIdPlaying,
  duration
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const handleConvertDuration = useCallback((duration: number = 0) => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor(duration % 3600 / 60);
    const secs = duration % 60;

    // Đảm bảo định dạng luôn có 2 chữ số
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(secs).padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }, []);

  // Cập nhật trạng thái `isPlaying` khi audio khác được phát
  useEffect(() => {
    if (!id || !idPlaying) return;
    if (idPlaying !== id) {
      setIsPlaying(false); // Nếu không phải audio đang phát, đặt về trạng thái không phát
    }
  }, [idPlaying, audioUrl]);
  const togglePlay = () => {
    try {
      if (!audioRef.current) return;
      if (currentlyPlayingRef.current && currentlyPlayingRef.current !== audioRef.current) {
        // Dừng audio đang phát trước đó
        currentlyPlayingRef.current.pause();
        currentlyPlayingRef.current.currentTime = 0; // Reset về đầu
      }
      if (isPlaying) {
        // Nếu audio này đang phát, tạm dừng nó
        audioRef.current.pause();
        setIsPlaying(false);
        setIdPlaying(null);
      } else {
        // Nếu audio này chưa phát, phát nó
        audioRef.current.play();
        setIsPlaying(true);
        setIdPlaying(id); // Cập nhật ID audio đang phát
        currentlyPlayingRef.current = audioRef.current; // Cập nhật tham chiếu
      }
    } catch (error) {
      console.log('Error when playing audio:', error);
    }
  };
  const handleAudioEnd = () => {
    setIsPlaying(false);
    setIdPlaying(null);
    currentlyPlayingRef.current = null;
  };
  return <div>
      <Button tone="critical" variant="plain" disabled={!audioUrl} onClick={togglePlay} icon={isPlaying ? PauseCircleIcon : PlayCircleIcon} accessibilityLabel="Play">
        {handleConvertDuration(duration) || '--:--:--'}
      </Button>
      <audio ref={audioRef} src={audioUrl} onEnded={handleAudioEnd} />
    </div>;
}
export default AudioPlayer;