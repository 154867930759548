import { useUpdateSetting } from 'queries/setting.query';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
export default function ZaloZNSApp_callback() {
  const location = useLocation();
  const {
    mutateAsync: updateEntity,
    error,
    isSuccess: updateSuccess,
    isPending: updating
  } = useUpdateSetting();
  const savingData = useCallback(async code => {
    try {
      await updateEntity({
        _zalo_callback_code: code
      });
      console.log('SV: Saving data success');
      window.close();
    } catch (e) {
      console.error(e);
    }
  }, []);
  useEffect(() => {
    let params = new URLSearchParams(location.search);
    let code = params.get('code');
    if (!code) {
      console.log('No code in URL');
      alert('No code in URL');
      return;
    }
    savingData(code);
  }, [location]);
  return <div>
      <h1>ZaloZNSApp_callback</h1>
    </div>;
}