import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Icon, InlineStack, ResourceItem, ResourceList, Scrollable, Spinner, Text } from '@shopify/polaris';
import { PhoneInIcon, PhoneOutIcon } from '@shopify/polaris-icons';
import dateandtime from 'date-and-time';
import CustomerNew from 'entities/customer/customer.new';
import helpers from 'helpers/index';
import { TypedPhoneCall, useMutateGetPhonelogs, useUpdatePhonelog } from 'queries/phonelog.query';
export default function PhoneCallLog({
  show
}: {
  show: boolean;
}) {
  const {
    mutateAsync: getEntities,
    data
  } = useMutateGetPhonelogs();
  const {
    mutateAsync: updateEntity
  } = useUpdatePhonelog();
  const [entities, setEntities] = useState<TypedPhoneCall[]>([]);
  const current_page = useRef(1);
  const [loading, setLoading] = useState(false);
  const [reachingEndData, setReachingEndData] = useState(false);
  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      let {
        body
      } = await getEntities({
        sort: 'createdAt:desc',
        limit: 20,
        page: current_page.current || 1
      });
      if (Array.isArray(body) && body.length < 1) {
        setReachingEndData(true);
      }
      let newData = [...body, ...entities];
      setEntities(newData);
    } catch (e) {}
    setLoading(false);
  }, []);
  useEffect(() => {
    if (!show) return;
    loadData();
  }, [show]);
  const [showNewCustomer, setShowNewCustomer] = useState(null);
  const currentNumber = useRef(null);
  const [currentPhonelogID, setCurrentPhonelogID] = useState(null);
  const doClick = useCallback((id: string, phonelog_number: any, user?: any) => {
    if (user) return;
    setCurrentPhonelogID(id);
    setShowNewCustomer(true);
    currentNumber.current = phonelog_number;
  }, [setCurrentPhonelogID]);
  const closeNewCustomerModal = useCallback(async (user_id: any) => {
    setShowNewCustomer(false);
    current_page.current = 1;
    if (currentPhonelogID) await updateEntity({
      id: currentPhonelogID
      // user_id: user_id,
    });
    await helpers.sleep(1000);
    await loadData();
  }, [currentPhonelogID]);
  const loadMoreAfterReachEnd = useCallback(() => {
    current_page.current = current_page.current + 1;
    loadData();
  }, [current_page]);
  const fetchDataMemo = useMemo(() => {
    if (reachingEndData) return;
    return helpers.debounce(() => {
      loadMoreAfterReachEnd?.call(this);
    }, 500);
  }, [loadMoreAfterReachEnd, reachingEndData]);
  const loadingMarkup = loading ? <InlineStack align="center" blockAlign="center" gap="200">
      <br />
      <Spinner accessibilityLabel="Loading more content" size="small" />
      <br />
    </InlineStack> : null;
  const getCallStatusForIcon = useCallback((disposition: string) => {
    switch (disposition) {
      case 'NO ANSWER':
        return 'caution';
      case 'ANSWERED':
        return 'success';
      case 'MISSED':
        return 'critical';
      case 'FAILED':
        return 'warning';
      case 'BUSY':
        return 'critical';
      default:
        return 'info';
    }
  }, []);
  return <div id="phone_call_log">
      {<CustomerNew onClose={closeNewCustomerModal} show={!!showNewCustomer} defaultNumber={currentNumber.current} />}

      <Scrollable onScrolledToBottom={fetchDataMemo} style={{
      height: 'calc(100vh - 35px)'
    }} shadow focusable>
        <ResourceList resourceName={{
        singular: 'customer',
        plural: 'customers'
      }} items={data?.body || []} renderItem={(item, index) => {
        const {
          id,
          direction,
          destination,
          source,
          disposition,
          source_data,
          destination_data,
          duration,
          createdAt
        } = item;
        return <ResourceItem key={index + '_uiuiu'} id={`${id}_ph`} url={source_data ? `/customer/view/${source_data.user_id}` : '#'} media={direction === 'inbound' ? <Icon tone={getCallStatusForIcon(disposition)} source={PhoneInIcon} /> : <Icon tone={getCallStatusForIcon(disposition)} source={PhoneOutIcon} />} onClick={() => {
          doClick(id, source, source_data);
        }}>
                <InlineStack gap="400" align="start" blockAlign="center">
                  <Text as="span" fontWeight="bold">
                    {source_data ? source_data.display_name : source}
                  </Text>
                  <Text as="span" tone="subdued">
                    {'-'}
                  </Text>
                  <Text as="span" tone="subdued">
                    {destination_data ? destination_data.display_name : destination}
                  </Text>
                </InlineStack>
                <Text tone="subdued" variant="bodySm" as="span">
                  {dateandtime.format(new Date(createdAt), 'HH:mm:ss DD-MM-YYYY')} - {duration}
                </Text>
              </ResourceItem>;
      }} />
        {reachingEndData && <Text as="p" alignment="center" tone="critical" variant="bodySm" fontWeight="bold">
            Không còn dữ liệu
          </Text>}
        {loadingMarkup}
      </Scrollable>
    </div>;
}