import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import queryClient from 'queries';

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/
/**
 *   Interface/model file auto generate for Phonelog
 *   Interface for back-end
 *   Model for front-end.
 *   They are the same!
 */
export interface TypedPhoneCall {
    id?: string;
    /** 
     * chiều gọi: inbound, outbound
     */
    direction?: string;

    source?: string; // gọi từ
    destination?: string; // gọi tới

    extension?: string; // số nghe cuối cùng
    /**
     * ['NO ANSWER', 'UNKNOWN', 'ANSWERED', 'MISSED', 'FAILED', 'BUSY']
     * trong đó, UNKNOWN là đang diễn ra, các giá trị khác thì as-is
     */
    disposition?: string; // kết quả cuộc gọi
    recordingfile?: string; // đường dẫn file ghi âm
    duration?: number; // thời gian gọi: tính bằng giây
    uuid?: string; // ID cuộc gọi
    createdAt?: any; // thời gian tạo

    "source_data"?: {
        "user_id": string,
        "user_phonenumber": string,
        "display_name": string,
    },
    "destination_data"?: {
        "user_id": string,
        "user_phonenumber": string,
        "display_name": string,
    }
}




type IQuery = TypedPhoneCall & IQueryParams;


/*
* List of all app
*/
export function useMutateGetPhonelogs() {
    return useMutation({
        mutationKey: ["phonelog/fetch_entity_list"],
        mutationFn: (object: IQuery) => axios.get<TypedPhoneCall[]>(`/phonelog${helpers.buildEndUrl(object)}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
    });
}


export function useUpdatePhonelog() {
    return useMutation({
        mutationKey: ['phonelog/update_entity'],
        mutationFn: ({ id, ...rest }: TypedPhoneCall) => axios.patch<TypedPhoneCall>(`/phonelog/${id}`, helpers.cleanEntity(rest)).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['phonelog/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['phonelog/fetch_entity'] });
        }
    });
}


export function useGetPhonelogs(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["phonelog/fetch_entities_list"],
        queryFn: () => axios.get<TypedPhoneCall[]>(`/phonelog${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: true,
        enabled: true,
    });
}



