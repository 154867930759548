import { useGetSettings } from 'queries/setting.query';
import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';

// Định nghĩa kiểu cho từng setting
type Setting = Record<string, any> | null;

// Định nghĩa kiểu cho Context
interface SettingsContextType {
  settings: Setting | null;
  loading: boolean;
}

// Context mặc định
const SettingsContext = createContext<SettingsContextType>({
  settings: null,
  loading: true
});

// Provider component
interface SettingsProviderProps {
  children: ReactNode;
}
export const SettingsProvider: React.FC<SettingsProviderProps> = ({
  children
}) => {
  const [settings, setSettings] = useState<Setting | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const {
    data: entities,
    isLoading: loadingSetting,
    isSuccess,
    isError
  } = useGetSettings({
    limit: 1000
  });
  useEffect(() => {
    if (isSuccess && entities) {
      setSettings(entities);
    }
    if (!loadingSetting) {
      setLoading(false);
    }
  }, [entities, isSuccess, loadingSetting]);
  return <SettingsContext.Provider value={{
    settings,
    loading
  }}>
      {isError ? <div style={{
      width: '100vw',
      height: '100vh'
    }}>
          <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        color: 'white',
        fontSize: '18px',
        textAlign: 'center',
        padding: '20px'
      }}>
            <div style={{
          color: 'red',
          padding: '15px 25px'
        }}>
              We can not load settings, please check your internet connection again...
            </div>
          </div>
        </div> : children}
    </SettingsContext.Provider>;
};

// Custom hook để sử dụng Context
export const useSettings = (): SettingsContextType => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
};